const NominationTypes = {
  Assistant: "Assistant(e)-Chef(taine)",
  Chef: "Chef(taine)",
  Commissaire: "Commissaire",
  CommissaireAdjoint: "Commissaire adjoint",
  CommissaireGroupes: "Commissaire aux groupes",
  CommissaireAdjGroupes: "Commissaire adjoint aux groupes",
  CommissaireFormation: "Commissaire à la formation",
  CommissaireAdjFormation: "Commissaire adjoint à la formation",
  CommissaireVert: "Commissaire à la branche verte",
  CommissaireAdjVert: "Commissaire adjoint à la branche verte",
  CommissaireBleu: "Commissaire à la branche bleue",
  CommissaireAdjBleu: "Commissaire adjoint à la branche bleue",
  CommissaireJaune: "Commissaire à la branche jaune",
  CommissaireAdjJaune: "Commissaire adjoint à la branche jaune",
  CommissaireRouge: "Commissaire à la branche rouge",
  CommissaireAdjRouge: "Commissaire adjoint à la branche rouge",
  Membre: "Membre",
  President: "Président(e)",
  VPGestion: "Vice-Président(e) à la Gestion",
  VPScout: "Commissaire Général",
  Administrateur: "Administrateur/Administratrice",
  ConseillerSpiri: "Conseiller spirituel",
  DCC: "Deputy Camp Chief",
};

export default NominationTypes;
